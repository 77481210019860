<template>
  <div class="container" id="label_orders_edit">
    <!-- Titel -->
    <v-row>
      <div class="show-inline bottom-margin">
        <h1 v-html="$t('label_data_detail_title')"></h1>
        <h1>: {{ labelDataHeader.agisId }} - {{ labelDataHeader.name }}</h1>
      </div>
    </v-row>

    <!-- Zugehörige Label -->
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <h2 v-html="$t('label_data_detail_label_belonging')"></h2>
        <v-data-table-virtual
          :multi-sort="true"
          :sort-by="sort"
          :loading="tableLoading"
          :headers="fields"
          :items="labelData"
          :hide-default-footer="true"
          disable-pagination
          hover
          class="no-margin-top"
        >
          <template v-slot:[`item.farm.tvdId`]="{ item }">
            <v-btn @click="dialogStart(item)">{{ item.farm.tvdId }}</v-btn>
          </template>
          <template v-slot:[`item.creation`]="{ item }">{{ $formatChDateTime(item.creation) }}</template>
          <template v-slot:[`item.certification.certifiedFrom`]="{ item }">
            {{ $formatDateTimeToChDate(item.certification.certifiedFrom) }}
          </template>
          <template v-slot:[`item.certification.certifiedTo`]="{ item }">
            {{ $formatDateTimeToChDate(item.certification.certifiedTo) }}
          </template>
          <template v-slot:[`item.animal_type_id`]="{ item }">
            {{ getAnimalName(item.animal_type_id) }}
          </template>
        </v-data-table-virtual>
      </v-col>
    </v-row>
    <v-row><v-col cols="12" sm="12" md="12"> &nbsp;</v-col> </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <h2 v-html="$t('label_data_detail_tvdinformations')"></h2>
        <v-data-table-virtual
          :loading="tvdUnitLocalUnitLoader"
          :headers="fieldsTvdUnitLocalUnit"
          :items="tvdUnitLocalUnits"
          :hide-default-footer="true"
          disable-pagination
          hover
          class="no-margin-top"
        >
          <template v-slot:[`item.validFrom`]="{ item }">
            {{ $formatDateTimeToChDate(item.validFrom) }}
          </template>
          <template v-slot:[`item.validUntil`]="{ item }">
            {{ $formatDateTimeToChDate(item.validUntil) }}
          </template>
        </v-data-table-virtual>
      </v-col>
    </v-row>
    <!-- Close Button -->
    <v-row>
      <div>
        <v-btn color="primary" class="shadow-none top-border" @click="close()"><span v-html="$t('close')" /></v-btn>
      </div>
    </v-row>
    <v-dialog :retain-focus="false" v-model="dialog" max-width="75%" id="label-data-dialog">
      <v-card>
        <v-card-title>
          <span class="text-h8" v-html="$t('label_data_detail_label_belonging_detail')" />
        </v-card-title>
        <v-card-text>
          <div>
            <pre>{{ dialogContentText }}</pre>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="closeDialog()">
            <span v-html="$t('close')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { Term } from '@/services/term'
import { SortItem } from '@/services/BackendService'
import { DTSHeaders } from '@/services/BackendService'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useLabelBundleStore } from '@/store/enums/LabelBundleStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'label_data_details',
  props: { localUnitId: { type: Number, required: true } },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  data() {
    return {
      sortBy: ['animal_type_id', 'norm.labelName', 'season'],
      sortDesc: [false, false, true],
      sort: <SortItem[]>[
        { key: 'animal_type_id', order: 'asc' },
        { key: 'norm.labelName', order: 'asc' },
        { key: 'season', order: 'desc' }
      ],
      labelData: [],
      labelBundle: {},
      tvdUnitLocalUnitLoader: false,
      tvdUnitLocalUnits: [],
      fieldsTvdUnitLocalUnit: <DTSHeaders>[
        {
          title: this.$t('label_data_detail_tvdUnitId'),
          value: 'tvdUnitId',
          sortable: true
        },
        {
          title: this.$t('label_data_detail_localUnitId'),
          value: 'localUnitId',
          sortable: true
        },
        {
          title: this.$t('label_data_detail_validFrom'),
          value: 'validFrom',
          sortable: true
        },
        {
          title: this.$t('label_data_detail_validUntil'),
          value: 'validUntil',
          sortable: true
        }
      ],
      fields: <DTSHeader[]>[
        // TVD-Nr.
        {
          title: this.$t('label_data_detail_table_tvd_nr'),
          value: 'farm.tvdId',
          sortable: true
        },
        {
          title: this.$t('label_data_animal_type'),
          value: 'animal_type_id',
          sortable: true
        },
        // Label
        {
          title: this.$t('label_data_detail_table_label'),
          value: 'norm.labelName',
          sortable: true
        },
        // Label
        {
          title: this.$t('label_data_detail_season'),
          value: 'season',
          sortable: true
        },
        // CertificationOrganisationStatus
        {
          title: this.$t('label_data_detail_certification_status'),
          key: 'certification.status',
          sortable: true
        },
        // CertifiedFrom
        {
          title: this.$t('label_data_detail_table_certified_from'),
          key: 'certification.certifiedFrom',
          sortable: true
        },
        // CertifiedUntil
        {
          title: this.$t('label_data_detail_table_certified_until'),
          key: 'certification.certifiedTo',
          sortable: true
        },
        // LabelAnimalTypeCode

        // Creation
        {
          title: this.$t('label_data_creation'),
          key: 'creation',
          sortable: true
        } // orderState
        // Kontroll-Organisation
        /*
        {
          title: this.$t('label_data_detail_table_control_organisation'),
          value: 'audit.controlOrganisationName',
          sortable: true
        },
        // Label Type
        {
          title: this.$t('label_data_detail_table_service'),
          value: 'norm.ServiceName',
          sortable: true
        },

        // CertificationOrganisationName
        {
          title: this.$t('label_data_detail_certification_organisation'),
          value: 'certification.certificationOrganisationName',
          sortable: true
        },

        // LabelDataId
        {
          title: this.$t('label_data_detail_table_id_api'),
          value: 'labeldataID',
          sortable: true
        },

        {
          title: this.$t('label_data_order_state'),
          value: 'orderState',
          sortable: true
        }
        */
      ],
      tableLoading: false,
      dialog: false,
      dialogContent: '',
      dialogContentText: 'initial',
      labelBundleLoading: false,
      dairies: [],
      dairy: {},
      dairyExists: false,
      errorMessage: '',
      labelDataHeader: {},
      headerLoading: false
    }
  },
  computed: {
    labelBundles() {
      return useLabelBundleStore().labelBundles
    },
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    },
    term(): any {
      return [...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)]
    }
  },
  methods: {
    getAnimalName(animal_type_id) {
      let ats = this.animalTypes.filter((i) => i.id == animal_type_id)
      return ats[0][this.$route.params.langKey]
    },
    closeDialog() {
      this.dialog = false
    },
    pretty(val, indent = 2) {
      if (typeof val == 'object') {
        try {
          val = JSON.parse(val)
        } catch (err) {
          console.warn('value is not JSON')
          return val
        }
        return JSON.stringify(val, null, indent)
      }
    },
    async loadLabelDataDetails() {
      this.tableLoading = true
      try {
        const result = await this.axios.get(apiURL + '/labelData/search', {
          params: {
            localUnitId: this.$route.params.localUnitId
          }
        })
        if (await result) {
          this.labelData = result.data
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.tableLoading = false
      }
    },
    async loadTvdUnitLocalUnit() {
      this.tvdUnitLocalUnitLoader = true
      try {
        const result = await this.axios.get(apiURL + '/tvdUnitLocalUnits/find', {
          params: {
            localUnitId: this.$route.params.localUnitId
          }
        })
        this.tvdUnitLocalUnits = result.data
      } catch (e) {
        showError(e)
      } finally {
        this.tvdUnitLocalUnitLoader = false
      }
    },
    async loadHeader() {
      this.headerLoading = true
      try {
        const result = await this.axios.get(apiURL + '/farms/' + this.$route.params.localUnitId)
        if (await result) {
          this.labelDataHeader = result.data
          //console.log('this.labelDataHeader', this.labelDataHeader)
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.headerLoading = false
      }
    },
    close() {
      this.$router.push({ name: 'label_data_read' }).catch(() => {
        /* duplicated route */
      })
    },
    dialogStart(item: any) {
      this.dialog = true
      this.dialogContent = item
      this.dialogContentText = this.pretty(item, 4)
    }
  },
  beforeMount() {
    useAnimalTypesStore().fetchAnimalTypes()
    useLabelBundleStore().fetchLabelBundles()
  },
  async mounted() {
    this.loadLabelDataDetails()
    this.loadTvdUnitLocalUnit()
    this.loadHeader()
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.top-border {
  margin-top: $spacer-md !important;
}

.show-inline {
  display: flex;
}

.bottom-margin {
  margin-bottom: $spacer-sm;
}
</style>
